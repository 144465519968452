import styled , {keyframes} from "styled-components";
import { colors } from "../../theme";

const toggle = keyframes`
    0%{opacity:.2}
    40%{opacity:1}
    100%{opacity:.2}
`

export const LoaderSquare = styled.div`
    display: flex;
    width: 20px;
    height: 20px;
    background-color:${colors.blue};
    margin: 2px;
`

export const LoaderSquaresContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 50px;
    div:nth-child(1){
        animation:${toggle} 1.2s ease-in infinite;
    }
    div:nth-child(2){
        animation:${toggle} 1.8s ease-in infinite;
    }
    div:nth-child(3){
        animation:${toggle} 1.4s ease-in infinite;
    }
    div:nth-child(4){
        animation:${toggle} 1.6s ease-in infinite;
    }

`

export const LoaderContainer = styled.div`
    display: flex;
    width:100%;
    height: 100%;
    overflow: hidden;
    background-color:${colors.dark};
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    z-index: 20;
`

export const LoaderTitle = styled.h1`
    margin:20px;
    color: white;
    font-weight: 700;

`