import React from "react"
import GlobalContextProvider from './src/components/GlobalContext'
import Layout from "./src/layout";    
import { Helmet } from "react-helmet"

  
  // for some types of tests you want a memory source
//   let source = createMemorySource("/starting/url")
//   let history = createHistory(source)
  
//   let App = () => (
//       <div>
//         Alright, we've established some location
//         context
//       </div>
//   )
// import GlobalContextProvider from "./GlobalContext.js"
export const wrapPageElement = ({element,props}) => {

    return(
        <GlobalContextProvider>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Smart Windows</title>
            </Helmet>
            <Layout {...props} pathname={props.location.pathname} home={props.location.pathname==='/' ? true : false}>
                {element}
            </Layout>
        </GlobalContextProvider>
)
}