import React, { useRef, useEffect, useState } from 'react';
import Markers from './Markers'
import mapboxgl from '!mapbox-gl';
 // or "const mapboxgl = require('mapbox-gl');"
import "mapbox-gl/dist/mapbox-gl.css"


const places = [
    {
      name: "The Metropolis Tower",
      latitude: 25.1849235,
      longitude: 55.274714,
    },
    // {
    //   name: "ALATHIBA 18 NOV ROAD",
    //   latitude: 23.6028685,
    //   longitude: 58.341148,
    // },
    // {
    //   name: "Birch Coffee",
    //   longitude: -73.92,
    //   latitude: 40.72,
    // },
    // {
    //   name: "B&H",
    //   longitude: -73.93,
    //   latitude: 40.73,
    // },
  ]

const Map = () => {

  mapboxgl.accessToken = 'pk.eyJ1IjoiZGEzYXoiLCJhIjoiY2tkdDBiZW84MDJjdDMwc2duMnUzN2o1NSJ9.0uJvyNtl-Fp-h2WapJKsWA';
  const mapContainerRef = useRef(null)
  const [map, setMap] = useState(null)
  
  useEffect(() => {
    const map = new mapboxgl.Map({
    container: mapContainerRef.current,
    style: 'mapbox://styles/mapbox/outdoors-v10?optimize=true', // optimize=true
    // center: [56.7, 24.5],
    center: [55.274714,25.1849235],
    minZoom:4,
    zoom: 7,
    maxZoom:17
    });
    // const marker1 = new mapboxgl.Marker()
    // .setLngLat([12.554729, 55.70651])
    // .addTo(map);
    
    // // Create a default Marker, colored black, rotated 45 degrees.
    // const marker2 = new mapboxgl.Marker({ color: 'black', rotation: 45 })
    // .setLngLat([12.65147, 55.608166])
    // .addTo(map);
    setMap(map)

    return () => map.remove()  
},[]);

  
  
 return(
          <div ref={mapContainerRef} className="map-container" id='map' style={{display:'flex',height:'100%',width:'100%'}}>
            {map && <Markers map={map} places={places} />}
          </div>
  )
}

export default Map
