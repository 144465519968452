import React from 'react'
import styled from "styled-components";
import { colors } from "../../theme";


export const GpsSvg = () => (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 2.5C20.9925 2.5 26.25 5.7725 26.25 9.5025C26.25 13.3688 21.5756 18.4175 15 25.3288C8.42438 18.4175 3.75 13.3688 3.75 9.5025C3.75 5.7725 9.0075 2.5 15 2.5V2.5ZM15 0C7.12875 0 0 4.25375 0 9.5025C0 14.75 6.50437 21.015 15 30C23.4956 21.015 30 14.75 30 9.5025C30 4.25375 22.8731 0 15 0V0ZM15 13.75C11.8931 13.75 9.375 12.0712 9.375 10C9.375 7.92875 11.8931 6.25 15 6.25C18.1069 6.25 20.625 7.92875 20.625 10C20.625 12.0712 18.1069 13.75 15 13.75Z" fill="#FFCF06"/>
    </svg>
)

export const InstagramSvg = () => (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 2.70375C19.005 2.70375 19.48 2.71875 21.0625 2.79125C25.1275 2.97625 27.0262 4.905 27.2112 8.94C27.2837 10.5212 27.2975 10.9963 27.2975 15.0013C27.2975 19.0075 27.2825 19.4813 27.2112 21.0625C27.025 25.0938 25.1313 27.0262 21.0625 27.2112C19.48 27.2837 19.0075 27.2988 15 27.2988C10.995 27.2988 10.52 27.2837 8.93875 27.2112C4.86375 27.025 2.975 25.0875 2.79 21.0613C2.7175 19.48 2.7025 19.0062 2.7025 15C2.7025 10.995 2.71875 10.5213 2.79 8.93875C2.97625 4.905 4.87 2.975 8.93875 2.79C10.5213 2.71875 10.995 2.70375 15 2.70375V2.70375ZM15 0C10.9262 0 10.4162 0.0175 8.81625 0.09C3.36875 0.34 0.34125 3.3625 0.09125 8.815C0.0175 10.4163 0 10.9262 0 15C0 19.0738 0.0175 19.585 0.09 21.185C0.34 26.6325 3.3625 29.66 8.815 29.91C10.4163 29.9825 10.9262 30 15 30C19.0738 30 19.585 29.9825 21.185 29.91C26.6275 29.66 29.6625 26.6375 29.9088 21.185C29.9825 19.585 30 19.0738 30 15C30 10.9262 29.9825 10.4162 29.91 8.81625C29.665 3.37375 26.6387 0.34125 21.1862 0.09125C19.585 0.0175 19.0738 0 15 0V0ZM15 7.2975C10.7462 7.2975 7.2975 10.7462 7.2975 15C7.2975 19.2538 10.7462 22.7038 15 22.7038C19.2538 22.7038 22.7025 19.255 22.7025 15C22.7025 10.7462 19.2538 7.2975 15 7.2975ZM15 20C12.2387 20 10 17.7625 10 15C10 12.2387 12.2387 10 15 10C17.7613 10 20 12.2387 20 15C20 17.7625 17.7613 20 15 20ZM23.0075 5.19375C22.0125 5.19375 21.2063 6 21.2063 6.99375C21.2063 7.9875 22.0125 8.79375 23.0075 8.79375C24.0013 8.79375 24.8062 7.9875 24.8062 6.99375C24.8062 6 24.0013 5.19375 23.0075 5.19375Z" fill="#FFCF06"/>
    </svg>
)

export const MailSvg = () => (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0V30H30V0H0ZM26.8975 3.33333L15 16.1883L3.1025 3.33333H26.8975V3.33333ZM2.5 26.6667V6.97167L15 20.4783L27.5 6.97167V26.6667H2.5Z" fill="#FFCF06"/>
    </svg>
)

export const PhoneSvg = () => (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 2.5C21.8925 2.5 27.5 8.1075 27.5 15C27.5 21.8925 21.8925 27.5 15 27.5C8.1075 27.5 2.5 21.8925 2.5 15C2.5 8.1075 8.1075 2.5 15 2.5ZM15 0C6.71625 0 0 6.71625 0 15C0 23.2837 6.71625 30 15 30C23.2837 30 30 23.2837 30 15C30 6.71625 23.2837 0 15 0ZM20.625 21.6388L18.425 17.3925L17.135 18.0237C15.735 18.7025 12.885 13.1362 14.2537 12.4025L15.5562 11.7613L13.3725 7.5L12.0563 8.15C7.555 10.4962 14.7025 24.3887 19.3062 22.285L20.625 21.6388V21.6388Z" fill="#FFCF06"/>
    </svg>
)

export const FooterContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color:${colors.green2};
    color: white;
    font-weight: 800;
    padding:100px;
    @media screen and (max-width: 400px) {
        padding:40px 10px;
    }
`

export const FooterIntro = styled.div`
    display: flex;
    max-width: 800px;
`

export const FooterContacts = styled.div`
    display: flex;
    flex-direction:row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 1100px) {
        flex-direction:column;

    }

`

export const FooterContactsElement = styled.div`
    display: flex;
    flex-direction: column;
    margin: 60px 0px;
`

export const FooterContactsContainer = styled.div`
    margin-left: 20px;
    display: flex;
    flex-direction: column;;
    justify-content: flex-start;
    @media screen and (max-width: 400px) {
        margin-left: 0px;

    }
`

export const FooterContact = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 20px;
    svg{
        margin: 10px 20px;
    }
   
`

export const FooterCredential = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80vw;
    flex-wrap: wrap;
    margin: auto;
    margin-top: 50px;
    p{
        font-size:15px;
        color: ${colors.gray};
        display: flex;
        justify-content:flex-start;
        margin-top: 20px;

    }
    p:last-child{
        font-weight: normal;
        opacity: .5;
        justify-content:flex-end;
    }
`

export const FooterMap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width:40vw;
    height:40vw;
    
    @media screen and (max-width: 1100px) {
        height:400px;
        flex: 1;
        margin: auto;
        width: 500px;
        >div{
            height:400px !important;
            width: 500px !important;

        }
    }
    @media screen and (max-width: 400px) {
        height:50vh;
        width: 70vw;
        margin:0px;
        >div{
            height:50vh !important;
            width: 70vw !important;
        }
       
    }
`