import React, { useEffect, useState } from "react"
import { StaticQuery,graphql } from "gatsby"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import { GlobalContext } from "../components/GlobalContext"
import Loader from "../components/Loader"
import styled from "styled-components"
import WhatsAppWidget from 'react-whatsapp-widget'
import 'react-whatsapp-widget/dist/index.css'

const Header = ({home,pathname}) => {

  return (
    <StaticQuery 
      query={query
      }
      render = { data => <Navbar pathname={pathname} logoImg={data.file.childImageSharp.fluid} home={home}/>}
    />
  )
}

const LayoutContainer = styled.div`
  *{
    direction: ${({language}) => language==='eng' ? 'ltr' : 'rtl'};
  }
  overflow: ${({loader}) => loader ? 'hidden' : 'auto'};
  height: ${({loader}) => loader ? '100vh' : 'auto'};;
`

const WhatsappContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 10px;
  bottom: 5px;
  z-index: 15;
`
export default function Layout({ children , home, pathname}) {
  const { language, setLanguage } = React.useContext(GlobalContext)
  const [loader,setLoader] = useState(true)

  useEffect(() => {
    setLoader(true)
    setTimeout(() => {
      setLoader(false)
    }, 4000)
  },[])

  return (
    <>
      {loader &&  <Loader></Loader>}
      <LayoutContainer loader={loader} language={language}>
      <Header home={home} pathname={pathname}/>
      {children}
      <Footer />
      <WhatsappContainer>
          <WhatsAppWidget phoneNumber='971507776227' />
      </WhatsappContainer>
    </LayoutContainer>
    </>
  )
}

export const query = 
graphql`
  query NavQuery {
    file(relativePath: {eq: "logo1.png"}) {
      id
      childImageSharp {
        fluid(maxWidth: 1000){
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`