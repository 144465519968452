import React from 'react'
import styled from 'styled-components'
import { colors } from '../../theme'

export const PointerSvg = () => (
    <svg width="30" height="30" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.5 20V0H0.5V20V40H20.5H40.5V20H20.5Z" fill="#FFCF06"/>
        <path d="M24.6861 0.216431H23.469V17.574H24.6861V0.216431Z" fill="#FFCF06"/>
        <path d="M27.7862 0.212402H26.5691V17.57H27.7862V0.212402Z" fill="#FFCF06"/>
        <path d="M30.8819 0.216431H29.6648V17.574H30.8819V0.216431Z" fill="#FFCF06"/>
        <path d="M33.9776 0.216431H32.7605V17.574H33.9776V0.216431Z" fill="#FFCF06"/>
        <path d="M37.0735 0.212402H35.8564V17.57H37.0735V0.212402Z" fill="#FFCF06"/>
        <path d="M40.1325 0.216431H38.9521V17.574H40.1325V0.216431Z" fill="#FFCF06"/>
    </svg>
)

export const DoorSvg = () => (
    <svg width="80" height="80" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M90 95.8333H100V99.9999H0V95.8333H10V-6.10352e-05H90V95.8333ZM85 4.16661H15V95.8333H85V4.16661ZM35 49.9999V54.1666H20V49.9999H35Z" fill={colors.blue}/>
    </svg>
)

export const WindowSvg = () => (
    <svg width="80" height="80" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0V100H100V0H0ZM45.8333 91.6667H8.33333V25H45.8333V91.6667ZM91.6667 91.6667H54.1667V62.5H91.6667V91.6667ZM91.6667 54.1667H54.1667V25H91.6667V54.1667Z" fill={colors.blue}/>
    </svg>
)

export const SvgContainer = styled.div`
    display: flex;
    justify-content:center;
    align-items: center;
`

export const AboutStyle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    color: black;
    font-weight: bolder;
    a{
        display: flex;
        justify-content: center;
        align-items: center;
    }
`

export const AboutContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 80vw;
    margin: 50px;
    width:80vw;
   
   
`

export const Title = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: BOLD;
    /* font-size: 24px; */
    /* text-shadow: 0px 0px 1px black; */
    h1{
        margin: 0 14px;
    }
`
export const Description = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    /* font-size: 20px; */
    margin: 30px 0px;
    justify-content: center;
    align-items: center;
    flex: 1;
    p{
        font-size: 2.5vmin;
    }
    @media screen and (max-width: 400px) {
      p{
        font-size: 3.5vmin;
      }  
    }
`
export const Description_image = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 200px;
    height: 200px;
    padding: 20px;
    margin:0px 20px;
    @media screen and (max-width: 960px) {
       display: none;
    }
`

export const Products = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin:30px 0px;
    padding:0px 80px;
    @media screen and (max-width: 400px) {
       padding: 0px;
       >div{
           margin: 20px 0px;
           box-shadow: none;

       }
    }
`

export const Product = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    color:${colors.blue};
    cursor: pointer;
    margin:20px 100px;
    transition: transform .2s ease-out;
    background-color: #8888;
    box-shadow: 5px 5px 5px #8888;

    p {
        /* font-size: 20px; */
        font-weight: bolder;
        margin-top: 10px;
        text-shadow: 1px 1px 1px ${colors.blue};
    }
    >div{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: white;
        transition: transform .2s linear;
    }
    &:hover>div {
        transform: translate(-2px,-4px);
    }

    @media screen and (max-width: 400px) {
        margin: 20px 0px;
        box-shadow: none;
        background-color: white;
        >div{
            box-shadow: 0px 0px 5px #8888;
            border-radius: 10px;
        }
        &:hover>div {
            transform: translate(0,0);
        }
    }
    
`