import React from 'react'
import {
    LoaderSquare,
    LoaderSquaresContainer,
    LoaderContainer,
    LoaderTitle
} from './LoaderComponents'
export default function Loader() {
    return (
        <LoaderContainer>
            <LoaderSquaresContainer>
                <LoaderSquare />
                <LoaderSquare />
                <LoaderSquare />
                <LoaderSquare />
            </LoaderSquaresContainer> 
            <LoaderTitle>Smart Windows</LoaderTitle>
        </LoaderContainer>
    )
}
