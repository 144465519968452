import React , { useState, useEffect } from 'react'
import { 
    Nav,
    NavLogo,
    MobileIcon,
    NavLinks,
    NavMenu,
    NavItem,
    Menu,
    Close,
    NavLanguage
} from './NavbarElements'
import Img from "gatsby-image"
import {texts} from '../../texts'
import { GlobalContext } from "../GlobalContext.js"
 




export default function Navbar({logoImg,home=true,pathname}) {
    const [click, setClick] = useState(false)
    const [scroll,setScroll] = useState(false)
    const { language, setLanguage } = React.useContext(GlobalContext)

    useEffect(() => {
        changeNav()
        window.addEventListener("scroll",changeNav)
    },[])

    function switchLanguge(){
        if(language === 'eng') setLanguage('ar')
        else setLanguage('eng')
    }

    const handleClick = () => setClick(!click)

    const changeNav = () =>{

        if(window.scrollY >= 150){
            setScroll(true)
        }else{
            setScroll(false)
        }
    }

    return (
          <Nav style={{direction:'ltr'}} active = {scroll } home={home} click = {click}>
             <NavLogo to = "/">
                <Img fluid={logoImg} />
             </NavLogo>
             <MobileIcon onClick = {handleClick}>
                 {click ? <Close/> : <Menu />}
             </MobileIcon>
             <NavMenu style={{direction:'ltr'}} onClick = {handleClick} click = {click} active = {scroll}>
                 {
                     texts.nav.map( (e,index) => (
                        <NavItem key={`${e.eng}_${index}`}>
                            <NavLinks selected = {pathname === e.link ? true : false} to={e.link}>{e[language]}</NavLinks>
                        </NavItem>
                     ))
                     
                 }
                <NavItem key={`languages`}>
                    <NavLanguage onClick={() => switchLanguge()}>
                        {
                            language === 'eng' ? texts.languages.arabic 
                                               : texts.languages.english
                        }
                    </NavLanguage>
                </NavItem>
             </NavMenu>
          </Nav>
    )
}
