import React from 'react'
import {
    GpsSvg,
    InstagramSvg,
    MailSvg,
    PhoneSvg,
    FooterContainer,
    FooterIntro,
    FooterContacts,
    FooterContactsElement,
    FooterContactsContainer,
    FooterContact,
    FooterCredential,
    FooterMap
} from './FooterElements'
import Map from './Map'
import {
    Title,
    PointerSvg,
}from '../About/AboutElements'
import { texts } from '../../texts'
import { colors } from '../../theme'
import { GlobalContext } from "../GlobalContext.js"

const svgs = [
    <PhoneSvg />,
    <InstagramSvg />,
    <MailSvg />,
    <GpsSvg />
]

export default function Footer() {
    const { language } = React.useContext(GlobalContext)

    return (
        <div style={{width:'100%',backgroundColor:colors.green2}} >
            <FooterContainer>
                <FooterIntro>
                    {texts.footer.title[language]}
                </FooterIntro>
                <FooterContacts>
                    <FooterContactsContainer>
                        {
                            texts.footer.contacts.map(
                                (el,i) => (
                                    <FooterContactsElement key={`${el.location[language]}_${i}`}>
                                        <p>{el.location[language]}</p>
                                        <FooterContactsContainer>
                                        {
                                            el.details[language].map((e,index) =>
                                            {
                                                if(index === 1){
                                                    return (
                                                            <FooterContact key={`${e}_${i}`} >
                                                                <a target="_blank" href={e.link} >{svgs[index]}</a>
                                                                <a target="_blank" href={e.link} >{e.name}</a>
                                                            </FooterContact>
                                                            )
                                                }else{
                                                    return(
                                                            <FooterContact key={`${e}_${i}`} >
                                                                {svgs[index]}
                                                                <p>{e}</p>
                                                            </FooterContact>
                                                    )
                                                }
                                            }
                                             
                                            )
                                        }
                                        </FooterContactsContainer>
                                    </FooterContactsElement>
                                )
                            )
                        }
                    </FooterContactsContainer>
                    <FooterMap>
                        <Map />
                    </FooterMap>
                </FooterContacts>
                <FooterCredential>
                        <p>
                            &copy; All rights reserved , Smart windows company
                        </p>
                        <p>
                            Built and designed by Chakib Sennoussi
                        </p>
                </FooterCredential>        
            </FooterContainer>
        </div>
    
    )
}
