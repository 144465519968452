import React from 'react'

export const texts = {
    nav : [
        {
            eng:'Home',
            ar:'الرئيسية',
            link:'/',
        },
        {
            eng:'Gallery',
            ar:'البوم الصور',
            link:'/gallery'
        },
        {
            eng:'Contact Us',
            ar:'تواصل معنا',
            link:'/contact'
        }
    ],
    languages:{
        arabic:'العربية',
        english:'English'
    },
    home : {
        h1: {
            eng:'Stay Smart , Stay with Smart windows',
            ar:'ابق سمارت ,ابق مع سمارت ويندوز'
        },
        h2:{
            eng:'Be sure of the quality of service',
            ar:'كن متيقنا من جودة الخدمة'
        },
        button1:{
            eng:'Contact Us',
            ar:'تواصل معنا',
            link:'/contact'
        },
        button2:{
            eng:'Check Our Gallery',
            ar:'تفقد البوم صورنا',
            link:'/gallery'
        }
    },
    about:{
        title:{
            eng:'About Us',
            ar:'من نحن'
        },
        description:{
            eng:<p>We are a professional UPVC system provider, well known for the quality of our premium windows and doors. <br /> <br /> We are always working to offer the best design your home, your business can get. </p>,
            ar:<p>سمارت ويندوز شركة مختصة في تصنيع النوافذ و الأبواب اليو بي في سي uPvc الألمانية بجودة و خبرة عالية , و تقديم أفضل الحلول المناسبة من ناحية التصميم و الأسعار .<br/> <br/> نحن نعمل دائمًا لتقديم أفضل تصميم يمكن لمنزلك الحصول عليه .</p>,
        },
        product1:{
            eng:'Windows',
            ar:'نوافذ'
        },
        product2:{
            eng:'Doors',
            ar:'ابواب'
        }
    },
    services:{
        title:{
            eng:'Why Choose Us',
            ar:'لماذا تختار خدماتنا'
        },
        services:[
            {
                eng:'The customer is our priority, which is why we strive to provide the best experience. ',
                ar:'العميل هو أولويتنا ، ولهذا نسعى جاهدين لتقديم أفضل تجربة.'
            },
            {
                eng:'We proudly say that our team is highly trained and experienced in designing , installing , maintaining UPVC products.',
                ar:'نقول بفخر أن فريقنا مدرب بدرجة عالية وذوي خبرة في تصميم وتركيب وصيانة منتجات UPVC.'
            },
            {
                eng:'We guarantee the effectiveness and durability of products and materials, and make sure that our customers remain confident in our work.',
                ar:'نحن نضمن فعالية ومتانة المنتجات والمواد ، ونتأكد من بقاء عملائنا على ثقة في عملنا.'
            },
            {
                eng:'Thanks to our organized team and engineers, we work professionally, stick to schedule and always respect the customer\'s time.',
                ar:'بفضل فريقنا المنظم ومهندسينا ، نعمل باحتراف ونلتزم بالجدول الزمني ونحترم وقت العميل دائمًا.'
            },
            {
                eng:'Always competitive prices with us',
                ar:'دائما اسعار تنافسية معنا. '
            },
        ]
    },
    break:{
        title:{
            eng:'It’s All About the Quality , And that is Our Speciality.',
            ar:'الأمر كله يتعلق بالجودة ، وهذا هو تخصصنا'
        },
        button:{
            eng:'Contact Us .',
            ar:'تواصل معنا .'
        }
    },
    showcase:{
        title:{
            eng:'Some of Our work',
            ar:'بعض أعمالنا'
        }
    },
    footer:{
        title:{
            eng:<p>
                Thanks for visiting us, feel free to Contact Us and be sure we will respond.
                <br/>
                Smart Windows , UPVC services leading company.
                </p>,
            ar:<p>
                شكرا لزيارتنا ، لا تتردد في الاتصال بنا وتأكد من أننا سنرد.
                <br/>
                سمارت وينداوز ، شركة رائدة في خدمات UPVC.
            </p>    
        },
        contacts:[
            {
                location:{
                    eng:'UAE, DUBAI, BUSINESS BAY',
                    ar:'الامارات العربية المتحدة - امارة دبي'
                },
                details:{
                    eng:[
                        '050 7776227 - 04 5833487',
                        {
                            link:'https://www.instagram.com/smartwindows.uae',
                            name:'@SMARTWINDOWS.UAE'
                        },
                        'info@smartupvcwindows.com',
                        'THE METROPOLIS TOWER OFFICE NO 713'
                    ],
                    ar:[
                        '5833487 04 - 7776227 050',
                        {
                            link:'https://www.instagram.com/smartwindows.uae',
                            name:'SMARTWINDOWS.UAE@'
                        },
                        'info@smartupvcwindows.com',
                        'ميتروبوليس تاور - مكتب رقم 713'
                    ]
                }
            },
            // {
            //     location:{
            //         eng:'OMAN - MUSCAT',
            //         ar:'سلطنة عمان - مسقط'
            //     },
            //     details:{
            //         eng:[
            //             '24137327 - 97070010 - 94930921',
            //             '@u.p.v.c_windows',
            //             'info@smartupvcwindows.com',
            //             'ALATHIBA 18 NOV ROAD'
            //         ],
            //         ar:[
            //             '94930921 - 97070010 - 24137327',
            //             'u.p.v.c_windows@',
            //             'info@smartupvcwindows.com',
            //             'العذيبة - شارع 18 نوفمبر بالقرب من مركز سلطان'
            //         ]
            //     }
            // },
        ]
    },
    gallery:{
        title:{
            eng:'Gallery',
            ar:'البوم الصور'
        },
        filters:[
            {
                name:'all',
                eng:'All',
                ar:'كل الصور'
            },
            {
                name:'image',
                eng:'Windows',
                ar:'النوافذ'
            },
            {
                name:'door',
                eng:'Doors',
                ar:'الابواب'
            }
        ]
        
    },
    contact:{
        title:{
            eng:'Contact Us',
            ar:'تواصل معنا'
        },
        intro:{
            eng:'Please feel free to send us an email and will get you answers and our quality services .',
            ar:'لا تتردد في إرسال بريد إلكتروني إلينا وسوف تحصل على إجابات وخدمات عالية الجودة.'
        },
        form:{
            subject:{
                eng:'Subject*',
                ar:'الموضوع*'
            },
            description:{
                eng:'Feel free to send us an email and we will reply for sure .',
                ar:'لا تتردد في إرسال بريد إلكتروني إلينا وسوف نقوم بالرد بالتأكيد .'
            },
            email:{
                eng:'Email*',
                ar:'الايميل*'
            },
            name:{
                eng:'Full Name',
                ar:'الاسم الكامل'
            },
            text:'......*'        
        },
        button:{
            eng:'Send',
            ar:'أرسل'
        },
        notification:{
            success:{
                eng:'Email sent successfully.',
                ar:'تم إرسال البريد الإلكتروني بنجاح.'
            },
            warn:{
                eng:'There was a problem sending the email.',
                ar:'كانت هناك مشكلة في إرسال البريد الإلكتروني.'
            }
        }
    },

}


