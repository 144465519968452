import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import {colors} from '../../theme'

export const Menu = () => (
  <svg width="34" height="20" viewBox="0 0 34 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line y1="2" x2="34" y2="2" stroke={colors.gray} strokeWidth="4"/>
    <line x1="4" y1="10" x2="34" y2="10" stroke={colors.gray} strokeWidth="4"/>
    <line x1="8" y1="18" x2="34" y2="18" stroke={colors.gray} strokeWidth="4"/>
  </svg>
)

export const Close = () => (
  <svg  width="24" height="24" fill={colors.gray} viewBox="0 0 24 24">
    <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/>
  </svg>
)

export const Nav = styled.nav`
  background: ${({active,home}) => !active && home ? 'transparent' : colors.dark};
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items:center;
  /* font-size:1rem; */
  /* position:${({home}) => home ? 'fixed' : 'inherit'}; */
  position: fixed;
  width: 100%;
  top:0;
  z-index:10;
  transition: background-color .5s ease-out;
  @media screen and (max-width:960px) {
      transition:0.8s all ease;
  }

`

export const NavLogo = styled(Link)`
 color:#141414;
 cursor:pointer;
 text-decoration:none;
 /* font-size:1.5rem; */
 display:flex;
 flex-direction: column;
 justify-content: center;
 color:#ff4040;
 width: 140px;
 height:80px;
 margin:0px 40px;
`
export const NavLanguage = styled.button`
  display:flex;
  align-items:center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-bottom: ${({selected}) => (selected ? '2px solid white' : 'none')};
  font-weight: normal;
  &:hover {
    color : #60CAE4;
    transition: all 0.3s ease;
    border-color:#60CAE4;
 }

 @media screen and (max-width: 960px) {
   text-align: center;
   padding: 2rem;
   width: 100%;
   display: table;
 }
 `

export const MobileIcon = styled.div`
  display : none;
  @media screen and (max-width:960px) {
      display:flex;
      position:absolute;
      width:40px;
      height:40px;
      align-items: center;
      justify-content: center;
      top:0;
      right:0;
      transform: translate(-100%,60%);
      /* font-size:1.8rem; */
      cursor:pointer ;
      svg {
        display: flex;

      }
      
  }
`
export const NavMenu = styled.ul`
 display : flex;
 align-items: center;
 list-style: none;
 text-align: center;
 flex-direction: row;
 opacity:${({active}) => (active ? 0.5 : 1)};
 font-weight: bold ;
 margin: 0px 40px;
 &:hover {
  opacity:1 !important;
 }
 @media screen and (max-width:960px){
   display: flex;
   flex-direction: column;
   width: 100%;
   height: 90vh;
   margin: 0px;
   position: absolute;
   top: ${({click}) => (click ? '80px' : "-1200px")};
   opacity:1;
   transition: all 0.2s ease;
   background: #fff;
   z-index: 999;
   a,button {
    color: black ;
    
    &:hover{
      border-bottom: 2px solid;
    }
   }
 }
 `

export const NavItem = styled.li`
 height : 80px;
 align-items:center;
 display:flex;
 color: white;
 margin:30px;

 @media screen and (max-width: 960px) {
   width:100%
 }

`


 export const NavLinks = styled(Link)`
  display:flex;
  align-items:center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-bottom: ${({selected}) => (selected ? '2px solid white' : 'none')};
  &:hover {
    color : #60CAE4;
    transition: all 0.3s ease;
    border-color:#60CAE4;
 }

 @media screen and (max-width: 960px) {
   text-align: center;
   padding: 2rem;
   width: 100%;
   display: table;
 }
 `


